<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Date & Time</th>
                        <th class="name">SR Name</th>
                        <th class="name">Dealer</th>
                        <th>Location</th>
                        <th class="name">Client</th>
                        <th>Status</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            {{ item.start_time }}
                        </td>

                        <td>
                            {{ item.sr_name }}
                        </td>

                        <td>
                            {{ item.dealer_name }}
                        </td>

                        <td>
                            {{ item.area_name }}
                        </td>

                        <td>
                            {{ item.client_name }}
                        </td>

                        <td>
                            <span class="badge rounded-pill me-1" :class="'badge-light-' + (item.status == 'active' ? 'success' : 'danger')">
                                {{ upperFirst(item.status) }}
                            </span>
                        </td>

                        <td>
                            <div class="action">
                                <button @click="onEditVisitPlanSchedule(item)" class="btn btn-primary btn-sm mr-5">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button @click="updateStatus(item)" class="btn btn-primary btn-sm">
                                    Update Status
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";
    import upperFirst from 'lodash/upperFirst';

    const route = useRoute();
    const router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        },
        onEditVisitPlan: Function,
    })

    const emit = defineEmits(['onEditVisitPlan']);

    const onEditVisitPlanSchedule = (item) => {
        emit('onEditVisitPlan', item)
    }

    const updateStatus = (item) => {
        let routeParams = Object.assign({}, route.params)
        routeParams.planId = item.id;

        router.push({
            name: 'visit-status-update',
            params: routeParams
        })
    }

</script>

<style scoped>
.mr-5{
    margin-right: 5px;
}
.action-th{
    width: 20%;
    text-align: center;
}
.name{
    width: 15%;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>