<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editData ? 'Edit' : 'Add' }} Visit Plan</h1>
                            <button 
                                @click="closeModal" 
                                type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="modal-body">
                    <div class="pb-2">
                        <div class="row">
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Start Time</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.start_time"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">End Time</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.end_time"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">BDO</label>
                                <AsyncSelect
                                    placeholder="Select BDO name"
                                    v-model="formData.sale_representative_id"
                                    :api-service="fetchAllSR"
                                    :reduce="option => option.id"
                                    :additional-option="additionalSROption"
                                    @option:selected="onchangeSrId"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Bank-Branch</label>
                                <AsyncSelect
                                    placeholder="Select Bank-Branch"
                                    v-model="formData.dealer_id"
                                    :api-service="fetchAllDealers"
                                    :additional-query="additionalDealerQuery"
                                    :reduce="option => option.id"
                                    :additional-option="additionalDealerOption"
                                    :key="rerenderKey"
                                    @option:selected="onSelectArea"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Location</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        name="location"
                                        type="text"
                                        v-model="formData.area_name"
                                        class="form-control"
                                        placeholder="Location"
                                        readonly
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Select Client</label>
                                <AsyncSelect
                                    placeholder="Select Client"
                                    v-model="formData.contact_profile_id"
                                    :api-service="fetchContactProfiles"
                                    :additional-query="{ type: 'customer'}"
                                    :reduce="option => option.id"
                                    :additional-option="additionalOption"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Status</label>
                                <v-select
                                    placeholder="Select status"
                                    :options="noteStatus" v-model="formData.status"
                                    :reduce="name => name.value"
                                    label="name"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Short Description</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        name="name"
                                        v-model="formData.short_description"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter short description"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Full Description</label>
                                <div class="input-group input-group-merge w-100">
                                    <textarea 
                                        v-model="formData.full_description"
                                        name="reference" rows="2"
                                        placeholder="Enter full description"
                                        class="form-control add-credit-card-mask"
                                    >
                                    </textarea>
                                </div>
                            </div>

                            <div class="col-12 text-center">

                                <hr class="mt-2">

                                <button 
                                    :disabled="loading" @click="submit"
                                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                                >
                                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    Submit
                                </button>

                                <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";
import handleHospital from '@/services/modules/hospital'
import handlePurchase from '@/services/modules/purchase';
import { getCurrentDateTime } from '@/services/utils/global.js'
import { inject, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import handleContact from '@/services/modules/contact';
import handleCrm from '@/services/modules/crm/crm'

const props = defineProps({
    editData: Object | null,
    onSubmit: Function,
    noteStatus: Array
})

const emit = defineEmits(['onSubmit']);

const { fetchContactProfiles } = handleContact();
const { fetchAreaList, storeVisitPlan, updateVisitPlan, fetchAllSR, fetchAllDealers } = handleCrm();

const store = useStore();
const route = useRoute();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const additionalOption = ref([]);
const additionalSROption = ref([]);
const additionalDealerOption = ref([]);
const additionalDealerQuery = ref({});
const rerenderKey = ref(0);

const formData = reactive({
    company_id: null,
    contact_profile_id: null,
    sale_representative_id: null,
    dealer_id: null,
    area_name: '',
    start_time: getCurrentDateTime(),
    end_time: '',
    short_description: '',
    full_description: '',
    status: 'active'
});

const closeModal = () => {
    store.state.isModalOpen = false;
}

const submit = async () => {

    formData.company_id = route.params.companyId;
    const id = props.editData ? props.editData.id : null;
    const visitPlanAction = id ? updateVisitPlan : storeVisitPlan;

    loading.value = true

    await visitPlanAction(formData, id).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        closeModal();
        emit('onSubmit');
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const onSelectArea = (dealer) => {
    formData.area_name = dealer.area_name
}

const onchangeSrId = () => {
    rerenderKey.value ++
    additionalDealerOption.value = [];
    formData.dealer_id = null;
    formData.area_name = '';
    additionalDealerQuery.value = { sale_representative_id: formData.sale_representative_id };
}

const setEditData = (data) => {

    formData.contact_profile_id = data.contact_profile_id
    formData.sale_representative_id = data.sale_representative_id
    formData.dealer_id = data.dealer_id
    formData.area_name = data.area_name
    formData.start_time = getCurrentDateTime(data.start_time);
    formData.short_description = data.short_description
    formData.full_description = data.full_description
    formData.activity_log_id = data.activity_log_id
    formData.status = data.status

    if(data.end_time) formData.end_time = getCurrentDateTime(data.end_time);

    if(data.contact_profile_id){
        additionalOption.value.push({
            id: data.contact_profile_id,
            name: data.client_name
        })
    }
    
    if(data.sale_representative_id){
        additionalSROption.value.push({
            id: data.sale_representative_id,
            name: data.sr_name
        })
    }

    if(data.dealer_id){
        additionalDealerOption.value.push({
            id: data.dealer_id,
            name: data.dealer_name
        })
    }
}

onMounted(() => {
    if(props.editData){
        setEditData(props.editData)
    }
})

</script>


<style scoped>
.med-title {
    padding: 0.486rem 1.5rem;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>